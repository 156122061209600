<template>
  <div>
    <component :is="layout"></component>
  </div>
</template>
<script>
import AuthLayout from "@/components/Layout/AuthLayout";
import NormalLayout from "@/components/Layout/Index";
export default {
  name: "App",
  components: {
    AuthLayout,
    NormalLayout
  },
  computed: {
    layout() {
      return this.$route.meta.layout;
    }
  },
  beforeCreate() {
    document.documentElement.setAttribute('dir', 'rtl')
  },
  setup() {
    return {};
  },
};
</script>