<template>
  <div class="services-main">
    <div class="container-sm container-md container-lg text-center">
      <b-row>
        <b-col sm="12" md="4" class="px-3 px-md-0 px-lg-5">
          <b-card class="border-0">
            <img alt="logo" src="@/assets/Learn-icon.png" />
            <h4>{{$t("Learn")}}</h4>
            <b-card-text>
              {{$t("Self-learning paths in digital skills, programming and Steam applications")}}
            </b-card-text>
            <b-button href="#" variant="outline-dark" class="shadow-sm"
              >{{$t("Sign up and start learning")}}</b-button
            >
          </b-card>
        </b-col>
        <b-col sm="12" md="4" class="sepretor px-3 px-md-0 px-lg-5">
          <b-card class="border-0">
            <img alt="logo" src="@/assets/Program-icon.png" />
            <h4>{{$t("Program")}}</h4>
            <b-card-text> {{$t("Practice your digital skills with experts")}}</b-card-text>
            <b-button href="#" variant="outline-dark" class="shadow-sm"
              >{{$t("Sign up and start learning")}}</b-button
            >
          </b-card>
        </b-col>

        <b-col sm="12" md="4" class="px-3 px-md-0 px-lg-5">
          <b-card class="border-0">
            <img alt="logo" src="@/assets/compete-icon.png" />
            <h4>{{$t("Compete")}}</h4>
            <b-card-text>
              {{$t("Compete solo or in an alliance in a series of quests and challenges and be at the forefront")}}
              {{$t("Future Skills Pioneers")}}
            </b-card-text>
            <b-button href="#" variant="outline-dark" class="shadow-sm"
              >{{$t("Sign up and start learning")}}</b-button
            >
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServicesSection",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
