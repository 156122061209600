<template>
  <div>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
  >
    <template #button-content>
      <!-- <b-img
        :src="currentLocale.img"
        height="14px"
        width="22px"
        :alt="currentLocale.locale"
      /> -->
      <span class="ml-50 text-body text-uppercase">{{ currentLocale.locale }}</span>
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="() => setLocale(localeObj.locale)"
    >
      <b-img
        :src="localeObj.img"
        height="14px"
        width="22px"
        :alt="localeObj.locale"
      />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
  </div>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'

export default {
  name: 'SelectLocale',
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  computed: {
    currentLocale() {
      return this.locales.find(l => l.locale === this.$i18n.locale)
    },
  },
  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale
      document.documentElement.setAttribute('dir', locale == 'ar' ? 'rtl' : "ltr")
    }
  },
  data() {
    return {
      locales: [
        {
          locale: 'en',
          img: require('@/assets/flags/en.png'),
          name: 'English',
        },
        {
          locale: 'ar',
          img: require('@/assets/flags/ar.png'),
          name: 'Arabic',
        }
      ]
    }
  }
}
</script>