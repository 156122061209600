<template>
  <div class="packages-main">
    <div class="container-sm container-md container-lg text-center">
      <b-row>
        <b-col sm="1" md="4" class="py-4 px-4 py-sm-0 px-md-3 px-lg-5">
          <b-card>            
            <h1>Basic <br>Package</h1>
            <div class="price-main">
                <div class="price">10.00
                    <p>150 {{$t("Riyals Annually")}}</p>
                </div> 
                <span>{{$t("Rial")}}<br>
               {{$t("Monthly")}}</span>
                
            </div>
            <b-card-text>
              {{$t("Training for 12 months competitions")}} <br>{{$t("Training for 12 months competitions")}}
            </b-card-text>
            <b-button href="#" variant="outline-dark" class="shadow-sm"
              >{{$t("Subscribe")}}</b-button
            >
          </b-card>
        </b-col>
        <b-col sm="1" md="4" class="py-4 px-4 py-sm-0 px-md-3 px-lg-5">
          <b-card class="active">            
            <h1>Silver <br>Package</h1>
            <div class="price-main">
                <div class="price">15.00
                    <p>300 {{$t("Riyals Annually")}}</p>
                </div> 
                <span>{{$t("Rial")}}<br>
               {{$t("Monthly")}}</span>
                
            </div>
            <b-card-text>
              {{$t("Training 12 months sequential training")}}
              {{$t("Training 12 months sequential training")}}
              {{$t("Training 12 months sequential training")}}
            </b-card-text>
            <b-button href="#" variant="outline-dark" class="shadow-sm"
              >{{$t("Subscribe")}}</b-button
            >
          </b-card>
        </b-col>
        <b-col sm="1" md="4" class="py-4 px-4 py-sm-0 px-md-3 px-lg-5">
          <b-card>            
            <h1>Gold <br>Package</h1>
            <div class="price-main">
                <div class="price">25.00
                    <p>500 {{$t("Riyals Annually")}}</p>
                </div> 
                <span>{{$t("Rial")}}<br>
               {{$t("Monthly")}}</span>
                
            </div>
            <b-card-text>
              {{$t("12-month STEAM Virtual Labs Training Methodology")}}
            </b-card-text>
            <b-button href="#" variant="outline-dark" class="shadow-sm"
              >{{$t("Subscribe")}}</b-button
            >
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "PackagesSection",
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

