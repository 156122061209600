<template>
  <div class="HomeView">        
    <div class="middle-main">
      <ServicesSection/>
      <LeaderboardSection/>
      <PackagesSection/>
      <SponsorSection/>      
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ServicesSection from '@/components/Landing/ServicesSection.vue'
import LeaderboardSection from '@/components/Landing/LeaderboardSection.vue'
import PackagesSection from '@/components/Landing/PackagesSection.vue'
import SponsorSection from '@/components/Landing/SponsorSection.vue'

export default {
  name: 'HomeView',
  components: {
    ServicesSection, LeaderboardSection, PackagesSection, SponsorSection
  },
}
</script>
