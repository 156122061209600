<template>
  <div class="leaderboard">
    <div class="title">
      <h1>{{$t("Leaderboard")}}</h1>
      <p>{{$t("The leaderboard list is updated throughout the day")}}</p>
    </div>
    <div class="board-main">
      <div class="container-sm container-md container-lg">
        <b-row>
          <b-col lg="6 mb-5 mb-lg-0">
            <div class="board-title">
              <span
                ><img alt="logo" src="@/assets/players-icon.png" />
                {{$t("Players")}}</span
              >
            </div>
            <table class="table player-table">
              <thead>
                <tr>
                  <th scope="col">{{$t("Country")}}</th>
                  <th scope="col">{{$t("Name")}}</th>
                  <th scope="col">{{$t("Code")}}</th>
                  <th scope="col">{{$t("Points")}}</th>
                  <th scope="col">WS</th>
                </tr>
              </thead>
               <tbody>
                <tr>
                  <th scope="row"><img alt="logo" src="@/assets/united-states.png" /></th>
                  <td>Barbara D. Cook</td>
                  <td>ASD</td>
                  <td>2350</td>
                  <td>2.15</td>
                </tr>
                <tr>
                  <th scope="row"><img alt="logo" src="@/assets/egypt.png" /></th>
                  <td>حسام علي</td>
                  <td>BGF</td>
                  <td>2350</td>
                  <td>2.15</td>
                </tr>
                <tr>
                  <th scope="row"><img alt="logo" src="@/assets/united-kingdom.png" /></th>
                  <td>Keith C. Chen</td>
                  <td>LKJ</td>
                  <td>2350</td>
                  <td>2.15</td>
                </tr>
                <tr>
                  <th scope="row"><img alt="logo" src="@/assets/qatar.png" /></th>
                  <td>السيد حسن محمد</td>
                  <td>SDF</td>
                  <td>2350</td>
                  <td>2.15</td>
                </tr>
                <tr>
                  <th scope="row"><img alt="logo" src="@/assets/united-states-2.png" /></th>
                  <td>Eric K. Omeara</td>
                  <td>ASD</td>
                  <td>2350</td>
                  <td>2.15</td>
                </tr>
                <tr>
                  <th scope="row"><img alt="logo" src="@/assets/united-arab-emirates.png" /></th>
                  <td>Ileana C. Seaman</td>
                  <td>EWR</td>
                  <td>2350</td>
                  <td>2.15</td>
                </tr>
                <tr>
                  <th scope="row"><img alt="logo" src="@/assets/egypt.png" /></th>
                  <td>إبراهيم ابوالحسن</td>
                  <td>SDF</td>
                  <td>2350</td>
                  <td>2.15</td>
                </tr>
                <tr>
                  <th scope="row"><img alt="logo" src="@/assets/united-states.png" /></th>
                  <td>HossamAly</td>
                  <td>WFW</td>
                  <td>2350</td>
                  <td>2.15</td>
                </tr>
                <tr>
                  <th scope="row"><img alt="logo" src="@/assets/united-states.png" /></th>
                  <td>Ileana C. Seaman</td>
                  <td>BDC</td>
                  <td>2350</td>
                  <td>2.15</td>                  
                </tr>
              </tbody>
            </table>
          </b-col>
          <b-col lg="6">
            <div class="board-title">
              <span
                ><img alt="logo" src="@/assets/Alliances-icon.png" />
                {{$t("Alliances")}}</span
              >
            </div>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">{{$t('Country')}}</th>
                  <th scope="col">{{$t("Code")}}</th>
                  <th scope="col">{{$t("Points")}}</th>
                  <th scope="col">WS</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row"><img alt="logo" src="@/assets/united-states.png" /></th>
                  <td>ASD</td>
                  <td>2350</td>
                  <td>2.15</td>
                </tr>
                <tr>
                  <th scope="row"><img alt="logo" src="@/assets/egypt.png" /></th>
                  <td>BGF</td>
                  <td>2350</td>
                  <td>2.15</td>
                </tr>
                <tr>
                  <th scope="row"><img alt="logo" src="@/assets/united-kingdom.png" /></th>
                  <td>LKJ</td>
                  <td>2350</td>
                  <td>2.15</td>
                </tr>
                <tr>
                  <th scope="row"><img alt="logo" src="@/assets/qatar.png" /></th>
                  <td>SDF</td>
                  <td>2350</td>
                  <td>2.15</td>
                </tr>
                <tr>
                  <th scope="row"><img alt="logo" src="@/assets/united-states-2.png" /></th>
                  <td>ASD</td>
                  <td>2350</td>
                  <td>2.15</td>
                </tr>
                <tr>
                  <th scope="row"><img alt="logo" src="@/assets/united-arab-emirates.png" /></th>
                  <td>EWR</td>
                  <td>2350</td>
                  <td>2.15</td>
                </tr>
                <tr>
                  <th scope="row"><img alt="logo" src="@/assets/egypt.png" /></th>
                  <td>SDF</td>
                  <td>2350</td>
                  <td>2.15</td>
                </tr>
                <tr>
                  <th scope="row"><img alt="logo" src="@/assets/united-states.png" /></th>
                  <td>WFW</td>
                  <td>2350</td>
                  <td>2.15</td>
                </tr>
                <tr>
                  <th scope="row"><img alt="logo" src="@/assets/united-states.png" /></th>
                  <td>BDC</td>
                  <td>2350</td>
                  <td>2.15</td>                  
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LeaderboardSection",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
