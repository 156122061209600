<template>
  <div class="sponsorsection">
    <div class="container-sm container-md container-lg">
       <carousel :autoplay="false" :nav="false" :dots="false" :responsive="{0:{items:2,nav:false},600:{items:3},1000:{items:5}}">                   
            <a href="#"><img alt="logo" src="@/assets/sponsor-logo.png" /></a>
            <a href="#"><img alt="logo" src="@/assets/sponsor-logo.png" /></a>
            <a href="#"><img alt="logo" src="@/assets/sponsor-logo.png" /></a>
            <a href="#"><img alt="logo" src="@/assets/sponsor-logo.png" /></a>
            <a href="#"><img alt="logo" src="@/assets/sponsor-logo.png" /></a>
            <a href="#"><img alt="logo" src="@/assets/sponsor-logo.png" /></a>
            <a href="#"><img alt="logo" src="@/assets/sponsor-logo.png" /></a>
            <a href="#"><img alt="logo" src="@/assets/sponsor-logo.png" /></a>
        </carousel>
    </div>
  </div>
</template>

<script>
export default {
  name: "SponsorSection",
};
</script>

<script>
import carousel from 'vue-owl-carousel'
export default {
    components: { carousel },
}

</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
