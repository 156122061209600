<template>
  <div>  
  <div class="header-main">
    <div class="container-sm container-md container-lg">    
       <b-row>
        <b-navbar toggleable="lg" type="dark" class="py-3">
        <b-col md="3" lg="2" class="col-6 order-sm-1 order-lg-1"><b-navbar-brand href="#">
          <img alt="logo" v-if="$i18n.locale==='ar'" src="@/assets/logo.png"/>
          <img alt="logo" v-else src="@/assets/logo-en.png"/>
          </b-navbar-brand></b-col>
        <b-col md="1" lg="6" z-index="3" class="col-2 ms-2 order-sm-2 order-md-3 order-lg-2">
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav class="justify-content-end">
          <b-navbar-nav class="p-0">
            <b-nav-item href="#">{{$t("Home")}}</b-nav-item>          
            <b-nav-item href="#">{{$t("Educational Content")}}</b-nav-item>
            <b-nav-item href="#">{{$t("Our Platforms")}}</b-nav-item>
            <b-nav-item href="#">{{$t("Read Content")}}</b-nav-item>
          </b-navbar-nav>
          </b-collapse>
        </b-col>
        <b-col md="7" lg="4" class="justify-content-center d-flex order-sm-3 order-md-2 order-lg-3 col-12 mt-3 mt-sm-0">
          <b-navbar-nav class=" p-0">            
            <b-row>
            <b-col sm="2" class="col-2 px-1"><div class="language"><Locale /></div></b-col>
            <b-col sm="4" class="col-4 px-1"><b-button @click="$router.push({path:'login'})" variant="outline-primary" class="mx-1">{{$t("Login")}}</b-button></b-col>
            <b-col sm="6" class="col-6 px-1"><b-button @click="$router.push({path:'signup'})" class="btn-primary mx-1">{{$t("New Registration")}}</b-button></b-col>
            </b-row>            
          </b-navbar-nav>
        </b-col>
        </b-navbar>
      </b-row>   
    <b-row>
      <b-col cols="12" md="7" lg="6" class="my-md-4 my-lg-0" >
        <div class="header-caption">
          <h1>{{$t("Learn to code, start creating solutions to the world's problems")}}</h1>
          <p class="py-2 py-md-4">{{$t("The Sattar educational platform provides you with diverse and integrated content that helps you learn programming with ease and fun")}}</p>
          <b-col>
            <b-button class="btn-primary mx-1">{{$t("Sign up and start learning")}}</b-button>
            <a href="#" class="play-btn pt-2 pt-sm-0"><img alt="logo" src="@/assets/play-icon.png">{{$t("Watch the video")}}</a>
          </b-col>
        </div>
      </b-col>
      <b-col cols="12" md="5" lg="6" class="pt-5 px-5 px-sm-0 pt-md-4 mt-md-5"><img alt="" src="@/assets/head-pic.png" class="img-fluid"></b-col>      
    </b-row>
    </div>
    </div>
  </div>
</template>


<script>

import {BRow, BCol} from "bootstrap-vue";
import Locale from "./Locale.vue";
export default {
  name: "HeaderComponent",
  components : { BRow, BCol, Locale }
};
</script>