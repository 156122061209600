<template>
  <div class="LayoutDefault">
    <header-component />
    <router-view />
    <footer-component />
  </div>
</template>
<script>
import HeaderComponent from "./HeaderComponent";
import FooterComponent from "./FooterComponent";
export default {
  name: "NormalLayout",
  components: {
    HeaderComponent,
    FooterComponent
  },
};
</script>