<template>
  <div class="footer-main">
    <div class="container-sm container-md container-lg">
        <div class="newletter-subscribe">
          <b-row>
          <b-col sm="1" md="5" class="text-center px-5 py-2 p-sm-0">
            <img alt="logo" src="@/assets/newslette-icon.png" class="img-fluid"/>
          </b-col>
          <b-col sm="1" md="7" class="px-4">
            <h3>{{$t("Subscribe to our newsletter for the latest updates")}}</h3>          
            <form action="#" method="Post">
              <input type="text" name="text" :placeholder="$t('Enter your email')">
              <button type="button" class="btn btn-default">{{$t("Subscribe")}}<i class="fa fa-envelope"></i></button>
            </form>
          </b-col>         
        </b-row>
        </div>
      <div class="foot-top">
        <b-row>
          <b-col sm="1" md="6" lg="4" class="order-md-3 order-lg-1 mb-4 mb-sm-0">            
            <img alt="logo" v-if="$i18n.locale==='ar'" src="@/assets/logo.png"/>
            <img alt="logo" v-else src="@/assets/logo-en.png"/>
            <p>{{$t("Maharrat Educational platform provides you with a diverse and integrated content that helps you learn programming with ease and fun")}}</p>
          </b-col>
          <b-col sm="1" md="6" lg="3" class="order-md-1 order-lg-1 mb-4 mb-sm-5 mb-lg-0">
            <h4>{{$t("Quick links")}}</h4>            
            <p><a href="#">{{$t("Home")}}</a></p>
            <p><a href="#">{{$t("Educational Content")}}</a></p>
            <p><a href="#">{{$t("Our Platforms")}}</a></p>
            <p><a href="#">{{$t("Read Content")}}</a></p>
          </b-col>
          <b-col sm="1" md="6" lg="2" class="order-md-2 order-lg-1 mb-4 mb-sm-5 mb-lg-0">
            <h4>{{$t("Support and help")}}</h4>            
            <p><a href="#">{{$t("Terms and Conditions")}}</a></p>
            <p><a href="#">{{$t("Privacy Policy")}}</a></p>
            <p><a href="#">{{$t("Support")}}</a></p>            
          </b-col>
          <b-col sm="1" md="6" lg="3" class="order-md-4 order-lg-1">
            <h4>{{$t("Contact Us")}}</h4>
            <div class="address">
              <p class="text-left"><img alt="logo" src="@/assets/phone.png"> (+966) 565 355 3563</p>
              <p><img alt="logo" src="@/assets/email.png"> support@domain.com</p>            
              <p><img alt="logo" src="@/assets/map.png"> 28 Km by Cairo / Alexandria Desert Road, 6 October, B148, Egypt</p>
            </div>
            <div class="socail">
              <a href="#"><img alt="logo" src="@/assets/fb.png"></a>
              <a href="#"><img alt="logo" src="@/assets/tw.png"></a>
              <a href="#"><img alt="logo" src="@/assets/in.png"></a>
              </div>
          </b-col>
        </b-row>
      </div>
    </div>

    <b-col class="copyright">
      <div class="container">
        
        {{ new Date().getFullYear() }} {{$t("All rights reserved to Digital Skills Platform")}}
      </div>
    </b-col>
  </div>
</template>
<script>
export default {
  name: "FooterComponent",
};
</script>